import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface ClassificationSnackbar {
  open: boolean
  id?: string
}

const initialState: ClassificationSnackbar = {
  open: false,
  id: undefined,
}

const classificationSnackbarSlice = createSlice({
  name: 'classificationSnackbarSlice',
  initialState,
  reducers: {
    openClassificationSnackbar: (_, action: PayloadAction<{ id: string }>) => {
      return { open: true, id: action.payload.id }
    },
    closeClassificationSnackbar: () => {
      return { open: false }
    },
  },
})

export const { openClassificationSnackbar, closeClassificationSnackbar } =
  classificationSnackbarSlice.actions
export default classificationSnackbarSlice.reducer
