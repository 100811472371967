import React, { useRef, useState } from 'react'
import { Box, Chip, type Theme, Typography, useTheme } from '@mui/material'
import { Education } from 'types'
import { useDispatch } from 'react-redux'
import { setActiveEducation } from 'api/slices/activeEducationSlice'
import { CustomPopper } from '../CustomPopper'
import SelectClassPopper from 'features/SelectClassPopper'
import WarningRounded from '@mui/icons-material/WarningRounded'
import CheckCircle from '@mui/icons-material/CheckCircle'
import AssistantRounded from '@mui/icons-material/AssistantRounded'
import CheckRounded from '@mui/icons-material/CheckRounded'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { SunCodeSuggestion } from 'types/Education.type'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

type StatusChipProps = {
  education: Education
  dynamic?: boolean
  selectedSunCode?: SunCodeSuggestion
}

const getStatusStyles = (theme: Theme, status: string) => {
  switch (status) {
    case 'unclassified':
      return {
        icon: (
          <WarningRounded
            sx={{
              fill: theme.palette.warning.dark,
              width: '16px',
              height: '16px',
            }}
          />
        ),
        color: theme.palette.warning.dark,
        background: theme.palette.surface?.orange,
      }
    case 'classified':
      return {
        icon: (
          <CheckCircle
            sx={{
              fill: theme.palette.secondary.dark,
              width: '16px',
              height: '16px',
            }}
          />
        ),
        color: theme.palette.secondary.dark,
        background: theme.palette.surface?.green,
      }
    case 'suggested':
      return {
        icon: (
          <AssistantRounded
            sx={{
              fill: theme.palette.primary.dark,
              width: '16px',
              height: '16px',
            }}
          />
        ),
        color: theme.palette.primary.dark,
        background: theme.palette.surface?.purple,
      }
    case 'search':
      return {
        icon: (
          <CheckRounded
            sx={{
              fill: theme.palette.info.dark,
              width: '16px',
              height: '16px',
            }}
          />
        ),
        color: theme.palette.info.dark,
        background: theme.palette.surface?.blue,
      }

    default:
      return {
        icon: (
          <WarningRounded
            sx={{
              fill: theme.palette.warning.dark,
              width: '16px',
              height: '16px',
            }}
          />
        ),
        color: theme.palette.warning.dark,
        background: theme.palette.surface?.orange,
      }
  }
}

const getStatus = (education: Education): string => {
  if (education.sunCodeApproved) return 'classified'
  if (education.sunCodeSuggestions && education.sunCodeSuggestions.length > 0)
    return 'suggested'

  return 'unclassified'
}

const getCode = (education: Education): string => {
  if (education.sunCode?.code) return education.sunCode.code
  if (education.sunCodeSuggestions?.[0].code)
    return education.sunCodeSuggestions[0].code
  return ''
}

const StatusChip = ({
  education,
  dynamic = true,
  selectedSunCode,
}: StatusChipProps): React.JSX.Element => {
  const {
    utbildningsklassificering: { classificationMissing },
  } = useEpiContent<Content>()

  const getLabel = (education: Education): string => {
    if (education.sunCode?.name) return education.sunCode.name
    if (education.sunCodeSuggestions?.[0].name)
      return education.sunCodeSuggestions[0].name
    return classificationMissing
  }

  const status = selectedSunCode?.code ? 'search' : getStatus(education)
  const code = selectedSunCode?.code
    ? selectedSunCode?.code
    : getCode(education)
  const label = selectedSunCode?.name
    ? selectedSunCode?.name
    : getLabel(education)

  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const statusStyles = getStatusStyles(theme, status)
  const chipRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handleOpen = () => {
    dispatch(setActiveEducation(education))
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!dynamic)
    return (
      <Chip
        data-testid="sun-category-chip-static"
        icon={statusStyles.icon}
        sx={{
          background: statusStyles.background,
          color: statusStyles.color,
          border: 0,
          paddingLeft: '8px',
        }}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            component={'span'}
          >
            <Typography variant="subtitle2" pr={1}>
              <strong>{code}</strong>
            </Typography>
            <Typography variant="subtitle2">{label}</Typography>
          </Box>
        }
      />
    )

  return (
    <>
      <Chip
        data-testid="sun-category-chip"
        icon={statusStyles.icon}
        sx={{
          background: statusStyles.background,
          color: statusStyles.color,
          border: 0,
          paddingLeft: '8px',
        }}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            component={'span'}
          >
            <Typography variant="subtitle2" pr={1}>
              <strong>{code}</strong>
            </Typography>
            <Typography variant="subtitle2">{label}</Typography>
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Box>
        }
        ref={chipRef}
        onClick={handleOpen}
      />
      <CustomPopper
        open={open}
        anchor={chipRef.current as HTMLElement}
        onClickAway={handleClose}
      >
        <SelectClassPopper closePopper={handleClose} />
      </CustomPopper>
    </>
  )
}

export default StatusChip
