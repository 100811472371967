import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Education } from 'types'

const initialState: Education = {
  id: '',
  name: '',
  description: '',
  categories: [],
  sunCodeSuggestions: [],
  sunCodeApproved: false,
  sunCodeApprovedDate: '',
  instituteName: '',
  link: '',
  createdDate: '',
  updatedDate: '',
  booked: false,
}

const activeEducationSlice = createSlice({
  name: 'activeEducationSlice',
  initialState,
  reducers: {
    setActiveEducation: (_, action: PayloadAction<Education>) => {
      return action.payload
    },
  },
})

export const { setActiveEducation } = activeEducationSlice.actions
export default activeEducationSlice.reducer
