import SearchRounded from '@mui/icons-material/SearchRounded'
import { Box, Stack, Typography, Divider, Chip } from '@mui/material'
import { SunCode } from 'types/SunCode.type'
import { SunCodeSuggestion } from 'types/Education.type'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { useClassifyEducationMutation } from 'api/kompetens/kompetens'
import { openClassificationSnackbar } from 'api/slices/classificationSnackbarSlice'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

interface SearchResultsProps {
  filteredSunCodes: SunCode[]
  closePopper: () => void
}

const SearchResults = ({
  filteredSunCodes,
  closePopper,
}: SearchResultsProps) => {
  const {
    utbildningsklassificering: { searchResults, noResults },
  } = useEpiContent<Content>()
  const dispatch = useDispatch()
  const activeEducation = useSelector(
    (state: RootState) => state.activeEducationSlice
  )
  const { id } = activeEducation
  const [classifyEducation] = useClassifyEducationMutation()

  const handleSelectSunCode = async (element: SunCodeSuggestion) => {
    const { name, code } = element

    await classifyEducation({
      id,
      code,
      name,
    })

    dispatch(openClassificationSnackbar({ id }))

    // Close popper
    closePopper()
  }

  return (
    <Box data-testid="search-results">
      <Stack direction={'row'} pt={3} pb={1} alignItems={'center'}>
        <SearchRounded
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
        <Typography ml={1} variant="body1">
          <strong>{searchResults}</strong>
        </Typography>
      </Stack>
      <Divider />
      {filteredSunCodes.length > 0 ? (
        <Stack pt={2} pb={2} gap={2}>
          {filteredSunCodes.map((element) => {
            return (
              <Chip
                data-testid="search-result-item"
                onClick={() => void handleSelectSunCode(element)}
                key={element.code}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    component={'span'}
                  >
                    <Typography variant="subtitle2" pr={1}>
                      <strong>{element.code}</strong>
                    </Typography>
                    <Typography variant="subtitle2">{element.name}</Typography>
                  </Box>
                }
              />
            )
          })}
        </Stack>
      ) : (
        <Typography paddingTop={1} variant="subtitle2">
          {noResults}
        </Typography>
      )}
    </Box>
  )
}

export default SearchResults
