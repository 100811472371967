import { useState } from 'react'
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid-pro'
import { useEpiContent } from '@trr/app-shell-data'
import { EducationsList } from './features/EducationsList'
import EducationDrawer from './features/EducationDrawer/EducationDrawer'
import { Content } from 'types/Content.type'

const App = () => {
  const {
    utbildningsklassificering: {
      heading,
      searchCoursesLabel,
      onlyShowUnclassifiedLabel,
      onlyShowBookedLabel,
    },
  } = useEpiContent<Content>()
  const [searchQuery, setSearchQuery] = useState('')
  const [filterList, setFilterList] = useState<string[]>([])
  const [onlyShowUnclassified, setOnlyShowUnclassified] = useState(true)
  const [onlyShowBooked, setOnlyShowBooked] = useState(true)

  return (
    <Box>
      <Typography component="h1" variant="h4" pb={4} data-testid="heading">
        {heading ? heading : 'Utbildningsklassificering'}
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 4 }}
        sx={{
          justifyContent: { sm: 'space-between' },
          alignItems: { sm: 'center' },
          paddingBottom: 2,
        }}
      >
        <TextField
          label={searchCoursesLabel}
          aria-label={searchCoursesLabel}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.currentTarget.value)
          }}
          data-testid="search-input"
          sx={{ width: { md: '580px' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <GridSearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormGroup sx={{ flexDirection: 'row', columnGap: 3 }}>
          <FormControlLabel
            control={
              <Switch
                data-testid="only-show-unclassified-switch"
                checked={onlyShowUnclassified}
                value={onlyShowUnclassified}
                onChange={(e) => {
                  setOnlyShowUnclassified(e.currentTarget.checked)
                }}
              />
            }
            label={onlyShowUnclassifiedLabel}
          />
          <FormControlLabel
            control={
              <Switch
                data-testid="only-show-booked-switch"
                checked={onlyShowBooked}
                value={onlyShowBooked}
                onChange={(e) => {
                  setOnlyShowBooked(e.currentTarget.checked)
                }}
              />
            }
            label={onlyShowBookedLabel}
          />
        </FormGroup>
      </Stack>

      <Stack direction={'row'} spacing={{ xs: 1 }} sx={{ paddingBottom: 4 }}>
        {filterList.map((el) => (
          <Chip
            data-testid="category-filter-list-item"
            key={el}
            label={el}
            onDelete={() => {
              setFilterList([])
            }}
          />
        ))}
      </Stack>

      <EducationsList
        onlyShowUnclassified={onlyShowUnclassified}
        onlyShowBooked={onlyShowBooked}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterList={filterList}
        setFilterList={setFilterList}
      />

      <EducationDrawer />
    </Box>
  )
}

export default App
