import {
  Paper,
  TextField,
  InputAdornment,
  Box,
  Divider,
  Button,
} from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetSunCodesQuery } from 'api/kompetens/kompetens'
import { SunCode } from 'types/SunCode.type'
import SunCodeSuggestionsList from './EducationsList/components/SunCodeSuggestionsList'
import SearchResults from './EducationsList/components/SearchResults'
import { fuzzySearch } from 'utils'
import { RootState } from 'store/store'
import { openEducationDrawer } from 'api/slices/educationDrawerSlice'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

export const SelectClassPopper = ({
  closePopper,
}: {
  closePopper: () => void
}) => {
  const {
    utbildningsklassificering: {
      viewAllClassificationsButtonLabel,
      searchBySunOrName,
    },
  } = useEpiContent<Content>()
  const dispatch = useDispatch()
  const activeEducation = useSelector(
    (state: RootState) => state.activeEducationSlice
  )

  const { sunCodeSuggestions } = activeEducation

  const { data: sunCodes } = useGetSunCodesQuery()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredSunCodes, setFilteredSunCodes] = useState<SunCode[]>([])

  const shouldDisplaySuggestions =
    sunCodeSuggestions && sunCodeSuggestions.length > 0

  useEffect(() => {
    if (sunCodes && searchQuery !== '') {
      const result = fuzzySearch(sunCodes, searchQuery)

      setFilteredSunCodes(result)
    }
  }, [searchQuery, sunCodes])

  return (
    <Paper
      elevation={6}
      sx={{ backgroundColor: 'white', padding: 2, width: '450px' }}
    >
      <TextField
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        label={searchBySunOrName}
        id="search-by-sun-or-name"
        data-testid="search-by-sun-or-name-input"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.currentTarget.value)
        }}
        sx={{ width: '100%' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <GridSearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {searchQuery !== '' ? (
        <SearchResults
          filteredSunCodes={filteredSunCodes}
          closePopper={closePopper}
        />
      ) : (
        shouldDisplaySuggestions && (
          <SunCodeSuggestionsList closePopper={closePopper} />
        )
      )}

      <Divider sx={{ paddingTop: 2 }} />
      <Box pt={2}>
        <Button
          data-testid="view-all-classifications-button"
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          onClick={() => dispatch(openEducationDrawer())}
        >
          {viewAllClassificationsButtonLabel}
        </Button>
      </Box>
    </Paper>
  )
}

export default SelectClassPopper
