import { createSlice } from '@reduxjs/toolkit'

interface EducationDrawer {
  open: boolean
}

const initialState: EducationDrawer = {
  open: false,
}

const educationDrawerSlice = createSlice({
  name: 'educationDrawerSlice',
  initialState,
  reducers: {
    openEducationDrawer: () => {
      return { open: true }
    },
    closeEducationDrawer: () => {
      return { open: false }
    },
  },
})

export const { openEducationDrawer, closeEducationDrawer } =
  educationDrawerSlice.actions
export default educationDrawerSlice.reducer
