import { Stack, Typography, Button, Box, useTheme } from '@mui/material'

import HatIllustration from './HatIllustration'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

interface NoRowsOverlayProps {
  setFilterList: React.Dispatch<React.SetStateAction<string[]>>
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

const NoRowsOverlay = ({
  setFilterList,
  setSearchQuery,
}: NoRowsOverlayProps) => {
  const {
    utbildningsklassificering: {
      noEducationsToShow,
      yourSearchNoResults,
      clearSearchAndFilterButtonLabel,
    },
  } = useEpiContent<Content>()
  const theme = useTheme()

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Box pb={2}>
        <HatIllustration />
      </Box>
      <Typography component="h2" variant="h5">
        {noEducationsToShow}
      </Typography>
      <Typography variant="body1" pb={3} color={theme.palette.text.secondary}>
        {yourSearchNoResults}
      </Typography>
      <Button
        onClick={() => {
          setSearchQuery('')
          setFilterList([])
        }}
        variant="outlined"
      >
        {clearSearchAndFilterButtonLabel}
      </Button>
    </Stack>
  )
}

export default NoRowsOverlay
