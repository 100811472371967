import { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { RootState } from 'store/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeEducationDrawer } from 'api/slices/educationDrawerSlice'
import { Education } from 'types'
import {
  useClassifyEducationMutation,
  useGetSunCodesHierarchyQuery,
} from 'api/kompetens/kompetens'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import OpenInNew from '@mui/icons-material/OpenInNew'
import CheckRounded from '@mui/icons-material/CheckRounded'
import { GridSearchIcon } from '@mui/x-data-grid-pro'
import { StatusChip } from 'components/StatusChip'
import { fuzzySearchHierarchy } from 'utils/fuzzySearch'
import { SunCode, SunCodeHierarchy } from 'types/SunCode.type'
import { HTMLMapper } from '@trr/html-mapper'
import { openClassificationSnackbar } from 'api/slices/classificationSnackbarSlice'
import { SunCodeSuggestion } from 'types/Education.type'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

const EducationDrawer = () => {
  const {
    utbildningsklassificering: {
      contractButtonLabel,
      expandButtonLabel,
      allSunCodes,
      alertMessage,
      abortButtonLabel,
      searchBySunOrName,
      browseAllSunCodes,
      classifyEducationButtonLabel,
    },
  } = useEpiContent<Content>()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [searchQuery, setSearchQuery] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [error, setError] = useState(false)
  const [selectedSunCode, setSelectedSunCode] = useState<SunCodeSuggestion>({
    code: '',
    name: '',
  })
  const [classifyEducationTrigger] = useClassifyEducationMutation()
  const educationDrawer = useSelector(
    (state: RootState) => state.educationDrawerSlice
  )
  const activeEducation = useSelector(
    (state: RootState) => state.activeEducationSlice
  )
  const { data: sunCodes } = useGetSunCodesHierarchyQuery()

  const [filteredSunCodes, setFilteredSunCodes] = useState<SunCodeHierarchy[]>(
    []
  )

  useEffect(() => {
    if (searchQuery === '') setFilteredSunCodes(sunCodes ?? [])
    if (sunCodes && searchQuery !== '') {
      const result = fuzzySearchHierarchy(sunCodes, searchQuery)

      setFilteredSunCodes(result)
    }
  }, [searchQuery, sunCodes])

  const classifyEducation = async (education: Education) => {
    if (!selectSunCode) {
      setError(true)
      return
    }
    setError(false)

    await classifyEducationTrigger({
      id: education.id,
      code: selectedSunCode.code,
      name: selectedSunCode.name,
    })

    dispatch(closeEducationDrawer())
    dispatch(openClassificationSnackbar({ id: education.id }))
    setSelectedSunCode({ code: '', name: '' })
  }

  const handleClose = () => {
    setError(false)
    dispatch(closeEducationDrawer())
    setSelectedSunCode({ code: '', name: '' })
  }

  const selectSunCode = (element: SunCode) => {
    const { name, code } = element
    setSelectedSunCode({ name, code })
    setError(false)
  }
  const showInstituteName =
    !activeEducation.link &&
    !activeEducation.description &&
    activeEducation.instituteName
  return (
    <Drawer
      data-testid="education-drawer"
      anchor="right"
      open={educationDrawer.open}
      onClose={handleClose}
    >
      <Box sx={{ width: '800px' }}>
        <Box
          sx={{
            paddingRight: 6,
            paddingLeft: 6,
            paddingTop: 6,
          }}
        >
          <Typography
            data-testid="header"
            variant="h3"
            component={'h1'}
            paddingBottom={2}
          >
            {activeEducation.name}
          </Typography>
          {showInstituteName && (
            <Typography paddingBottom={3}>
              {activeEducation.instituteName}
            </Typography>
          )}
          {activeEducation.description && (
            <>
              <Collapse collapsedSize={100} in={expanded}>
                <Typography data-testid="description">
                  {HTMLMapper({
                    body: activeEducation.description,
                    mediaUrl: '',
                  })}
                </Typography>
              </Collapse>
              <Button
                data-testid="expand-contract-button"
                startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                variant="text"
                onClick={() => {
                  setExpanded(!expanded)
                }}
                sx={{ paddingLeft: 0, marginBottom: 3 }}
              >
                {expanded ? contractButtonLabel : expandButtonLabel}
              </Button>
            </>
          )}
          <Box paddingBottom={3}>
            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
              <StatusChip
                education={{
                  ...activeEducation,
                }}
                dynamic={false}
                selectedSunCode={selectedSunCode}
              />
              {activeEducation.categories
                ? activeEducation.categories.map((el) => (
                    <Chip key={el} label={el} />
                  ))
                : null}

              {activeEducation.link && (
                <Button
                  variant="text"
                  startIcon={<OpenInNew />}
                  target="_blank"
                  sx={{
                    padding: 0,
                    '& .MuiButton-startIcon': { marginLeft: '0px' },
                  }}
                  href={activeEducation.link}
                >
                  {activeEducation.instituteName}
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        <Divider />
        {sunCodes && sunCodes.length > 0 && (
          <Box
            sx={{
              paddingTop: 3,
              paddingLeft: 6,
              paddingRight: 6,
              paddingBottom: 10,
            }}
          >
            <TextField
              label={searchBySunOrName}
              aria-label={searchBySunOrName}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.currentTarget.value)
              }}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <GridSearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction={'row'} paddingTop={6} paddingBottom={1}>
              <Typography variant="body1" flexGrow={1}>
                <strong>{allSunCodes}</strong>
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {browseAllSunCodes}
              </Typography>
            </Stack>
            <Divider />
            <Stack>
              {filteredSunCodes.map((sunCode) => {
                return (
                  <Box marginTop={4} key={sunCode.sunCode.id}>
                    <Typography variant="body2" paddingBottom={2}>
                      <strong>{sunCode.sunCode.name}</strong>
                    </Typography>
                    <Stack
                      direction={'row'}
                      spacing={2}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {sunCode.children.map((child) =>
                        selectedSunCode.code === child.code ? (
                          <Chip
                            data-testid="search-result-item"
                            sx={{
                              color: theme.palette.info.dark,
                              background: theme.palette.surface?.blue,
                              border: 0,
                              paddingLeft: '8px',
                            }}
                            icon={
                              <CheckRounded
                                sx={{
                                  fill: theme.palette.info.dark,
                                  width: '16px',
                                  height: '16px',
                                }}
                              />
                            }
                            key={child.id}
                            onClick={() => {
                              selectSunCode(child)
                            }}
                            label={
                              <>
                                <strong>{child.code} </strong>
                                {child.name}
                              </>
                            }
                          />
                        ) : (
                          <Chip
                            data-testid="search-result-item"
                            key={child.id}
                            onClick={() => {
                              selectSunCode(child)
                            }}
                            label={
                              <>
                                <strong>{child.code} </strong>
                                {child.name}
                              </>
                            }
                          />
                        )
                      )}
                    </Stack>
                  </Box>
                )
              })}
            </Stack>
          </Box>
        )}

        <Box
          width={'800px'}
          position={'fixed'}
          bottom={0}
          right={0}
          textAlign={'right'}
          sx={{ background: 'white' }}
        >
          {error && <Alert severity="error">{alertMessage}</Alert>}
          <Divider sx={{ paddingBottom: 2 }} />
          <Button
            data-testid="drawer-abort-button"
            onClick={handleClose}
            variant="text"
          >
            {abortButtonLabel}
          </Button>
          <Button
            data-testid="drawer-classify-button"
            variant="text"
            onClick={() => void classifyEducation(activeEducation)}
          >
            {classifyEducationButtonLabel}
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default EducationDrawer
